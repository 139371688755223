import dayGridPlugin from '@fullcalendar/daygrid';
import FullCalendar from '@fullcalendar/react';
import {
  Box,
  Chip,
  ThemeProvider,
  Tooltip,
  createTheme,
  useTheme,
} from '@mui/material';
import React from 'react';

export type SpotsCalendarProps = {
  slots: {
    date: string;
    hour: number;
    minute: number;
    name: string;
    spotId: number;
    spotIndex: number;
  }[];
};

const colors = [
  '#5048E5',
  '#9C27B0',
  '#EF5350',
  '#D81B60',
  '#4DB6AC',
  '#4FC3F7',
  '#C5E1A5',
  '#66BB6A',
  '#D4E157',
  '#FFD54F',
  '#FFF176',
  '#4363d8',
  '#f58231',
  '#911eb4',
  '#46f0f0',
  '#f032e6',
  '#bcf60c',
  '#fabebe',
  '#008080',
  '#e6beff',
  '#9a6324',
  '#fffac8',
  '#800000',
  '#aaffc3',
  '#808000',
  '#ffd8b1',
  '#000075',
  '#808080',
  '#ffffff',
  '#000000',
];

export function SpotsCalendar({ slots }: SpotsCalendarProps) {
  const theme = useTheme();
  const eventsForCalendar = slots.map((event, index) => {
    const { date, hour, minute } = event;
    const [year, month, day] = date.split('-');
    const eventDate = new Date(
      parseInt(year, 10),
      parseInt(month, 10) - 1,
      parseInt(day, 10),
      hour,
      minute,
    );

    return {
      id: index.toString(),
      title: event.name,
      start: eventDate,
      end: eventDate,
      color: colors[event.spotIndex % colors.length],
      // color: 'red'
    };
  });

  const eventContent = (info: any) => {
    const newTheme = createTheme({
      ...theme,
      palette: {
        ...theme.palette,
        primary: {
          main: info.event.backgroundColor,
        },
      },
    });

    return (
      <Tooltip title={info.event.title}>
        <ThemeProvider theme={newTheme}>
          <Chip
            label={info.timeText}
            color="primary"
            // variant="outlined"
            size="small"
            sx={{
              fontWeight: '500',
              userSelect: 'none',
              fontSize: 10,
              fontFamily: 'Roboto Mono',
            }}
          />
        </ThemeProvider>
      </Tooltip>
    );
  };

  return (
    <Box
      sx={{
        '& .fc-daygrid-day-events': {
          display: 'flex',
          flexWrap: 'wrap',
          pl: 1,
        },
        '& .fc-event': {
          marginRight: 0,
          marginLeft: 0,
          marginTop: 0,
        },
      }}
    >
      <FullCalendar
        plugins={[dayGridPlugin]}
        initialView="dayGridMonth"
        events={eventsForCalendar}
        eventTimeFormat={{
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: false,
        }}
        // @ts-ignore
        // eventRender={eventRender}
        eventContent={eventContent}
      />
    </Box>
  );
}
