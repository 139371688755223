import {
  FormInput,
  HasuraDataTableColumnDef,
  TablePageLayout,
  DataTableEx,
  DataTableExRef,
  HiddenInput,
  ConfigurationContext,
  NotificationsContext,
  FileInput,
} from '@kirz/mui-admin';
import { Button, Stack, Typography } from '@mui/material';
import { AlertCircle, Link } from 'mdi-material-ui';
import React, { useContext, useMemo, useRef } from 'react';

import { UserContext } from 'contexts/UserContext';
import { HideStationSelector } from 'layouts/HideStationSelector';

export function StationSettings() {
  const { hasura } = useContext(ConfigurationContext);
  const { user } = useContext(UserContext);
  const { showAlert } = useContext(NotificationsContext);

  const tableRef = useRef<DataTableExRef>(null);

  const columns = useMemo<HasuraDataTableColumnDef[]>(
    () => [
      {
        field: 'name',
        headerName: 'Station',
        type: 'relationship',
      },
      {
        field: 'settings { *metaStationCode* }',
        headerName: 'Station code',
        type: 'relationship',
        flex: 1,
      },
      {
        field: 'settings { *metaCategoryId* }',
        headerName: 'Category ID',
        type: 'relationship',
        flex: 1,
      },
      {
        field: 'settings { *ascPath* }',
        headerName: 'Sales planning path',
        type: 'relationship',
        flex: 1,
      },
      {
        field: 'settings { *ascExportPath* }',
        headerName: 'Sales planning export path',
        type: 'relationship',
        flex: 1,
      },
      {
        field: 'settings { *audioExportPath* }',
        headerName: 'Audio export path',
        type: 'relationship',
        flex: 1,
      },
      {
        field: 'settings { *maxTimeRestrictBlockLength* }',
        headerName: 'Max time restrict block length',
        type: 'relationship',
        flex: 1,
      },

      {
        field: 'settings { *appConnectionToken* }',
        headerName: 'Connection',
        type: 'relationship',
        width: 180,
        renderCell({ value, row }) {
          const enabled =
            row.settings?.metaStationCode &&
            row.settings?.metaCategoryId &&
            row.settings?.maxTimeRestrictBlockLength;

          if (!enabled) {
            return (
              <Stack direction="row" alignItems="center" spacing={1}>
                <AlertCircle color="error" />
                <Typography color="red" variant="body2">
                  Settings missing
                </Typography>
              </Stack>
            );
          }

          return (
            <Button
              size="small"
              variant="contained"
              startIcon={<Link />}
              onClick={(e) => {
                e.stopPropagation();

                navigator.clipboard.writeText(value);
                showAlert(`Connection Token copied to clipboard`, 'success');
              }}
            >
              Connect to App
            </Button>
          );
        },
      },
    ],
    [],
  );

  return (
    <HideStationSelector>
      <TablePageLayout
        title="Stations"
        // actionContent={
        //   <Button
        //     sx={{ ml: 5 }}
        //     variant="contained"
        //     startIcon={<TextBoxPlusOutline />}
        //     onClick={async () => {
        //       tableRef.current?.openFormDialog();
        //     }}
        //   >
        //     Add path
        //   </Button>
        // }
      >
        <DataTableEx
          id="paths-table"
          ref={tableRef}
          source="station"
          columns={columns}
          deletable={false}
          sortBy={{ field: 'id', sort: 'desc' }}
          persistStateMode="query"
          formTitle={(isNew) => (isNew ? 'New path' : 'Edit settings')}
          formDialogProps={{
            formProps: {
              defaultValues: {
                companyId: user.companyId,
              },
            },
            formFetcherProps: {
              onSelection() {
                return [
                  'id settings { id ascPath audioExportPath ascExportPath noAudioExportPath maxTimeRestrictBlockLength metaStationCode metaCategoryId logoFileId beepFileId defaultSpotPerDay }',
                ];
              },
              onFetch(item) {
                return {
                  ...item,
                  ...item.settings,
                  settingsId: item.settings?.id,
                };
              },
            },
            formSubmitterProps: {
              async preSubmit({ settingsId, id: stationId, ...item }) {
                if (settingsId) {
                  await hasura.request({
                    type: 'mutation',
                    action: 'update',
                    source: 'stationSettings',
                    where: {
                      id: { _eq: stationId },
                    },
                    set: item,
                  });
                } else {
                  await hasura.request({
                    type: 'mutation',
                    action: 'insertOne',
                    source: 'stationSettings',
                    item: {
                      id: stationId,
                      companyId: user.companyId,
                      ...item,
                    },
                  });
                }

                return {};
              },
            },
          }}
          selectProps={{
            filter: {
              companyId: { _eq: user.companyId },
            },
          }}
          searchFilter={{
            inputProps: {
              placeholder: 'Search by path',
            },
            filter: (search) => ({
              _or: search.flatMap((str) => [
                { ascPath: { _ilike: `%${str}%` } },
                { ascExportPath: { _ilike: `%${str}%` } },
                { exportPath: { _ilike: `%${str}%` } },
                { noAudioExportPath: { _ilike: `%${str}%` } },
              ]),
            }),
          }}
        >
          <FormInput
            name="metaStationCode"
            label="Station code"
            required
            placeholder="01"
            sm={6}
          />
          <FormInput
            name="metaCategoryId"
            label="Category ID"
            required
            sm={6}
          />
          <FormInput
            name="maxTimeRestrictBlockLength"
            label="Max time restrict block length"
            type="number"
            required
            sm={6}
          />
          <FormInput
            name="defaultSpotPerDay"
            label="Default spots per day"
            type="number"
            required
            sm={6}
          />
          <FormInput
            name="ascPath"
            label="Sales planning path (absolute path)"
            // required
            placeholder="/absolute/path/to/folder"
            helperText="Will be filled on app 1-st connection"
          />
          <FormInput
            name="ascExportPath"
            label="Sales planning export path (absolute path)"
            // required
            placeholder="/absolute/path/to/folder"
            helperText="Will be filled on app 1-st connection"
          />
          <FormInput
            name="audioExportPath"
            label="Audio export path (absolute path)"
            // required
            placeholder="/absolute/path/to/folder"
            helperText="Will be filled on app 1-st connection"
          />
          <FormInput
            name="noAudioExportPath"
            label="No audio export path (absolute path)"
            // required
            placeholder="/absolute/path/to/folder"
            helperText="Will be filled on app 1-st connection"
          />
          <FileInput
            name="logoFileId"
            label="Logo image"
            helperText="Image will be scaled down preserving aspect ratio"
            sm={6}
          />
          <FileInput name="beepFileId" label="Beep audio" sm={6} />
          <HiddenInput name="settingsId" />
          <HiddenInput name="id" />
        </DataTableEx>
      </TablePageLayout>
    </HideStationSelector>
  );
}
