import { Box, IconButton, Skeleton, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';
import { useAtomValue } from 'jotai';
import { ArrowLeft, ArrowRight } from 'mdi-material-ui';
import React, { Dispatch, SetStateAction } from 'react';

import { SpotType } from 'constants/other';
import { getDaysArray } from 'utils/getDaysArray';
import { getWeekDay } from 'utils/getWeekDay';

import { SpotOverviewCalendarWeekHour } from './SpotOverviewCalendarWeekHour';
import { useSpotOverview } from './SpotOverviewContext';
import { TotalCounter } from './TotalCounter';
import { WeekCounter } from './WeekCounter';
import {
  columnWidth,
  firstColumnWidth,
  hourModeColumnWidth,
  lastColumnWidth,
} from './utils';

export type TimeSlot = {
  weekDay: number;
  hour: number;
  minute: number;
};

const weekDays = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su'];

type SpotOverviewCalendarWeekProps = {
  week: { start: string; end: string };
  selectedWeekIdx: number;
  setSelectedWeekIdx: Dispatch<SetStateAction<number>>;
  skeleton?: boolean;
};

export function SpotOverviewCalendarWeek({
  week,
  skeleton,
  selectedWeekIdx,
  setSelectedWeekIdx,
}: SpotOverviewCalendarWeekProps) {
  const { stationSlotsAtom, daypartSlotsAtom, spot } = useSpotOverview();
  const stationSlots = useAtomValue(stationSlotsAtom);
  const daypartSlots = useAtomValue(daypartSlotsAtom);
  const isSingleWeek =
    week.start === spot.startDate && week.end === spot.endDate;

  const days = getDaysArray(week.start, week.end);
  const hours = [...new Array(24).keys()].filter((hour) => {
    return !!daypartSlots.find((x) => x.hour === hour);
  });

  const headerHeight = 34;
  const rowHeight = 28 * spot.station.blocks.length + 8 + 20;
  const footerHeight = 40;

  return (
    <Stack direction="column" sx={{ mt: 4, flex: 1 }}>
      <Stack spacing={2} direction="row" sx={{ mb: 1 }}>
        {['start', ...days, 'end'].map((day) => {
          if (day === 'start') {
            return (
              <Box
                key={day}
                sx={{
                  height: headerHeight,
                  width: firstColumnWidth,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {!isSingleWeek && (
                  <IconButton
                    sx={{
                      visibility: selectedWeekIdx === 0 ? 'hidden' : 'visible',
                    }}
                    onClick={() => {
                      setSelectedWeekIdx((x) => x - 1);
                    }}
                  >
                    <ArrowLeft />
                  </IconButton>
                )}
              </Box>
            );
          }

          if (day === 'end') {
            return (
              <Box
                key={day}
                sx={{
                  height: headerHeight,
                  width: firstColumnWidth,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                {!isSingleWeek && (
                  <IconButton
                    sx={{
                      visibility:
                        week.end === spot.endDate ? 'hidden' : 'visible',
                    }}
                    onClick={() => {
                      setSelectedWeekIdx((x) => x + 1);
                    }}
                  >
                    <ArrowRight />
                  </IconButton>
                )}
              </Box>
            );
          }

          return (
            <Box
              key={day}
              sx={{
                height: headerHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: columnWidth,
              }}
            >
              <Typography
                sx={{
                  fontWeight: '600',
                  fontSize: 16,
                  textTransform: 'uppercase',
                }}
              >
                {weekDays[getWeekDay(day)]}
              </Typography>
              <Typography sx={{ fontWeight: '500', fontSize: 14 }}>
                {dayjs(day).format('DD.MM')}
              </Typography>
            </Box>
          );
        })}
      </Stack>

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flex: 1,
        }}
      >
        <Box sx={{ flex: '1 1 0px', overflow: 'hidden', pb: 1 }}>
          <Stack
            spacing={2}
            direction="row"
            sx={{
              maxHeight: '100%',
              overflow: 'auto',
              pt: 1,
            }}
          >
            {[null, ...days].map((day) => {
              return (
                <Stack key={day} spacing={2}>
                  {hours.map((hour) => {
                    const key = hour;
                    const headerHeight = 60;

                    if (day === null) {
                      if (hour === null) {
                        return <Box key={key} sx={{ height: headerHeight }} />;
                      }

                      return (
                        <Box
                          key={key}
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: firstColumnWidth,
                            height: rowHeight,
                            flexShrink: 0,
                          }}
                        >
                          <Typography sx={{ fontSize: 18, fontWeight: '500' }}>
                            {hour.toString().padStart(2, '0')}
                          </Typography>
                        </Box>
                      );
                    }

                    if (skeleton) {
                      return (
                        <Skeleton
                          key={key}
                          variant="rectangular"
                          width={columnWidth}
                          height={20 + stationSlots.length * 28 + 8}
                          sx={{
                            flexShrink: 0,
                            borderRadius: 1,
                            boxShadow:
                              '0px 2px 4px rgb(31 41 55 / 23%), 0px 4px 6px rgb(100 116 139 / 16%)',
                          }}
                        />
                      );
                    }

                    return (
                      <SpotOverviewCalendarWeekHour
                        date={day}
                        hour={hour}
                        skeleton={skeleton}
                      />
                    );
                  })}
                  <Box sx={{ height: 4, flexShrink: 0 }} />
                </Stack>
              );
            })}
          </Stack>
        </Box>
      </Box>

      <Stack spacing={2} direction="row">
        {['start', ...days, 'end'].map((day) => {
          if (day === 'start') {
            return (
              <Box
                key={day}
                sx={{
                  height: footerHeight,
                  width: firstColumnWidth,
                  display: 'flex',
                  alignItems: 'center',
                  flexShrink: 0,
                }}
              />
            );
          }

          if (day === 'end') {
            return (
              <Stack
                direction="column"
                key={day}
                sx={{
                  height: footerHeight,
                  width: lastColumnWidth,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexShrink: 0,
                  position: 'relative',
                }}
              >
                <Typography
                  sx={{
                    fontWeight: '500',
                    fontSize: 20,
                  }}
                >
                  <TotalCounter
                    dates={getDaysArray(spot.startDate, spot.endDate)}
                  />
                </Typography>
                <Typography
                  sx={{
                    position: 'absolute',
                    bottom: '-8px',
                    left: 0,
                    right: 0,
                    textAlign: 'center',
                    fontSize: 12,
                  }}
                >
                  Total
                </Typography>
              </Stack>
            );
          }

          return (
            <Box
              key={day}
              sx={{
                height: footerHeight,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
                width: columnWidth,
                flexShrink: 0,
              }}
            >
              <Typography
                sx={{
                  fontWeight: '500',
                  fontSize: 20,
                }}
              >
                <WeekCounter date={day} />
              </Typography>
            </Box>
          );
        })}
      </Stack>
    </Stack>
  );
}
